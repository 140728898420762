<template>
  <div class="about-me">
    <h1>About Blake</h1>
      <p>I'm a Canadian actor and writer based out of Toronto. I started acting in musicals when I was 10 and found myself auditioning for every show available to me until I graduated high school.
          <br><br>I still perform in musicals and theatre and have branched out into professional film and television. You can find me constantly working on my craft and acting across Canada. Please reach out via email with any inquiries!
      </p>
  </div>
</template>

<style scoped>
  .about-me h1 {
    text-align: center;
  }

  .about-me p {
    text-align: justify;
    margin: 2%;
  }
</style>